'use strict';

var GetIntrinsic = require('get-intrinsic');

var $TypeError = GetIntrinsic('%TypeError%');

var DefineOwnProperty = require('../helpers/DefineOwnProperty');

var FromPropertyDescriptor = require('./FromPropertyDescriptor');

var OrdinaryGetOwnProperty = require('./OrdinaryGetOwnProperty');

var IsDataDescriptor = require('./IsDataDescriptor');

var IsExtensible = require('./IsExtensible');

var IsPropertyKey = require('./IsPropertyKey');

var SameValue = require('./SameValue');

var Type = require('./Type'); // https://ecma-international.org/ecma-262/6.0/#sec-createdataproperty


module.exports = function CreateDataProperty(O, P, V) {
  if (Type(O) !== 'Object') {
    throw new $TypeError('Assertion failed: Type(O) is not Object');
  }

  if (!IsPropertyKey(P)) {
    throw new $TypeError('Assertion failed: IsPropertyKey(P) is not true');
  }

  var oldDesc = OrdinaryGetOwnProperty(O, P);
  var extensible = !oldDesc || IsExtensible(O);
  var immutable = oldDesc && (!oldDesc['[[Writable]]'] || !oldDesc['[[Configurable]]']);

  if (immutable || !extensible) {
    return false;
  }

  return DefineOwnProperty(IsDataDescriptor, SameValue, FromPropertyDescriptor, O, P, {
    '[[Configurable]]': true,
    '[[Enumerable]]': true,
    '[[Value]]': V,
    '[[Writable]]': true
  });
};