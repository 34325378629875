
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
import Prism from "prismjs";
import "prismjs/components/prism-elixir";
import "prismjs/components/prism-erlang";
import { CodeSurfer, CodeSurferColumns, Step } from "code-surfer";
import { github } from "@code-surfer/themes";
import { Notes, Footer, Image, Appear, Steps } from "mdx-deck";
import { themes } from "mdx-deck";

import traceError from "./images/trace-error.png";
import traceOK from "./images/trace-ok.png";
import bass from "./images/bass.jpg";
import br from "./images/br.png";
import jasper from "./images/jasper.jpg";
import kilgore from "./images/kilgore.jpg";
import maya from "./images/maya.jpg";
import punch from "./images/punch.gif";
import olive from "./images/olive2.jpg";
import boss from "./videos/boss.mp4";
import Layout from "./layout";
export const theme = { ...github,
  ...{
    colors: { ...github.colors,
      background: "white"
    },
    styles: {
      root: {},
      a: {
        color: "#417be6",
        textDecoration: "none"
      }
    }
  }
};

const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Layout mdxType="Layout">
      <h1>{`Debugging Live Systems`}</h1>
      <h2><a parentName="h2" {...{
          "href": "https://twitter.com/jeffutter",
          "title": "Jeffery Utter"
        }}>{`@jeffutter`}</a></h2>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Jeff Utter`}</h1>
      <Appear mdxType="Appear">
        <Image src={bass} width="80%" size="contain" mdxType="Image" />
      </Appear>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Topeka, KS`}</h1>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <Image src={br} width="80vw" size="contain" mdxType="Image" />
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Olive`}</h1>
      <Appear mdxType="Appear">
        <Image src={olive} width="100%" size="contain" mdxType="Image" />
      </Appear>
    </Layout>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`If you see inadvertently bouncing on video... now you know why`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Debugging Live Systems`}</h1>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`What is 'Debugging Live Systems'?`}</h2>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">{`Not Tracing`}</li>
          <li parentName="ul">{`Not Monitoring`}</li>
          <li parentName="ul">{`Not Step Debugging`}</li>
          <li parentName="ul"><strong parentName="li">{`Last Resort for Production Issues`}</strong></li>
        </ul>
      </Steps>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`The Problem:`}</h1>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Hello Jeff... what's happening?`}</h3>
      <video autoPlay loop muted>
  <source src={boss} type="video/mp4" />
      </video>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Tracing`}</h2>
      <Appear mdxType="Appear">
        <p>{`Sometimes the error is obvious!`}</p>
        <Image src={traceError} width="80vw" size="contain" mdxType="Image" />
      </Appear>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Tracing`}</h2>
      <Appear mdxType="Appear">
        <p>{`Sometimes the error is nowhere to be found!`}</p>
        <Image src={traceOK} width="80vw" size="contain" mdxType="Image" />
        <p>{`Enter Remote Debugging!`}</p>
      </Appear>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Tools built in to the BEAM`}</h2>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Step 1: Remote console`}</h3>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Step 2: Beam Debugging Tools`}</h3>
      <p>{`Of course the beam comes with built in debugging tools!`}</p>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`:sys`}</inlineCode>{` module`}</li>
          <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`:erlang.trace`}</inlineCode>{` / `}<inlineCode parentName="strong">{`:erlang.trace_pattern`}</inlineCode></strong></li>
          <li parentName="ul"><strong parentName="li"><inlineCode parentName="strong">{`:dbg`}</inlineCode></strong></li>
        </ul>
      </Steps>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3><inlineCode parentName="h3">{`:erlang.trace`}</inlineCode></h3>
    </Layout>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`BIF`}</li>
        <li parentName="ul">{`Foundation for all other tracing tools in the BEAM`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`The tracing process`}</h3>
      <p><inlineCode parentName="p">{`:erlang.trace`}</inlineCode>{` requires a process to receive the tracing information`}</p>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "title=\"Trace Process\" 2:4",
            "title": "\"Trace",
            "Process\"": true,
            "2:4": true
          }}>{`defmodule Tracer do
  def start do
    spawn(&loop/0)
  end

  def loop do
    receive do
      msg ->
        IO.inspect(msg)
        loop()
    end
  end
end
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "title=\"Trace Process\" 6:12",
            "title": "\"Trace",
            "Process\"": true,
            "6:12": true
          }}>{``}</code></pre>
      </CodeSurfer>
    </Layout>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`Receives traces messages and logs them to the console`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Trace a process`}</h3>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "title=\"Our test process\"",
            "title": "\"Our",
            "test": true,
            "process\"": true
          }}>{`defmodule Echo do
  def start do
    spawn(&loop/0)
  end

  def call(pid, msg) do
    send(pid, {self(), msg})

    receive do
      msg ->
        msg
    end
  end

  defp loop do
    receive do
      {from, message} when is_pid(from) ->
        send(from, message)
        loop()
    end
  end
end
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "title=\"Our test process\" subtitle=\"Run a process in a loop\" 2:4",
            "title": "\"Our",
            "test": true,
            "process\"": true,
            "subtitle": "\"Run",
            "a": true,
            "process": true,
            "in": true,
            "loop\"": true,
            "2:4": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "title=\"Our test process\" subtitle=\"Receive a message and send back the same message\" 15:21",
            "title": "\"Our",
            "test": true,
            "process\"": true,
            "subtitle": "\"Receive",
            "a": true,
            "message": true,
            "and": true,
            "send": true,
            "back": true,
            "the": true,
            "same": true,
            "message\"": true,
            "15:21": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "title=\"Our test process\" subtitle=\"Public function to call the process and wait for response\" 6:13",
            "title": "\"Our",
            "test": true,
            "process\"": true,
            "subtitle": "\"Public",
            "function": true,
            "to": true,
            "call": true,
            "the": true,
            "process": true,
            "and": true,
            "wait": true,
            "for": true,
            "response\"": true,
            "6:13": true
          }}>{``}</code></pre>
      </CodeSurfer>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Start the process and trace logger\"",
            "subtitle": "\"Start",
            "the": true,
            "process": true,
            "and": true,
            "trace": true,
            "logger\"": true
          }}>{`iex(1)> echo_pid = Echo.start()
#PID<0.125.0>
iex(2)> tracer_pid = Tracer.start()
#PID<0.127.0>
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Setup tracing\"",
            "subtitle": "\"Setup",
            "tracing\"": true
          }}>{`iex(1)> echo_pid = Echo.start()
#PID<0.125.0>
iex(2)> tracer_pid = Tracer.start()
#PID<0.127.0>
iex(3)> :erlang.trace(echo_pid, true, [:all, {:tracer, tracer_pid}])
1
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Trace all processes and ports\" 5[39:44]",
            "subtitle": "\"Trace",
            "all": true,
            "processes": true,
            "and": true,
            "ports\"": true,
            "5[39:44]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Tell erlang where to send traces\" 5[45:66]",
            "subtitle": "\"Tell",
            "erlang": true,
            "where": true,
            "to": true,
            "send": true,
            "traces\"": true,
            "5[45:66]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Call the echo process\"",
            "subtitle": "\"Call",
            "the": true,
            "echo": true,
            "process\"": true
          }}>{`iex(1)> echo_pid = Echo.start()
#PID<0.125.0>
iex(2)> tracer_pid = Tracer.start()
#PID<0.127.0>
iex(3)> :erlang.trace(echo_pid, true, [:all, {:tracer, tracer_pid}])
1
iex(4)> Echo.call(echo_pid, :hi)
:hi
{:trace_ts, #PID<0.125.0>, :in, {Echo, :loop, 0}, 3, {1588, 360682, 682641}}
{:trace_ts, #PID<0.125.0>, :receive, {#PID<0.109.0>, :hi}, 3, {1588, 360682, 682645}}
{:trace_ts, #PID<0.125.0>, :send, :hello, #PID<0.109.0>, 3, {1588, 360682, 682648}}
{:trace_ts, #PID<0.125.0>, :out, {Echo, :loop, 0}, 3, {1588, 360682, 682650}}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Timestamp Traces\" 9[1:10],10[1:10],11[1:10],12[1:10]",
            "subtitle": "\"Timestamp",
            "Traces\"": true,
            "9[1:10],10[1:10],11[1:10],12[1:10]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Pid that was traced\" 9[13:25],10[13:25],11[13:25],12[13:25]",
            "subtitle": "\"Pid",
            "that": true,
            "was": true,
            "traced\"": true,
            "9[13:25],10[13:25],11[13:25],12[13:25]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Trace Event\" 9[28:30],10[28:35],11[28:32],12[28:31]",
            "subtitle": "\"Trace",
            "Event\"": true,
            "9[28:30],10[28:35],11[28:32],12[28:31]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Event Details\" 9[33:48],10[38:57],11[35:55],12[34:49]",
            "subtitle": "\"Event",
            "Details\"": true,
            "9[33:48],10[38:57],11[35:55],12[34:49]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"I have no idea!\" 9[51],10[60],11[58],12[52]",
            "subtitle": "\"I",
            "have": true,
            "no": true,
            "idea!\"": true,
            "9[51],10[60],11[58],12[52]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Timestamp\" 9[54:75],10[63:84],11[61:82],12[55:76]",
            "subtitle": "\"Timestamp\"",
            "9[54:75],10[63:84],11[61:82],12[55:76]": true
          }}>{``}</code></pre>
      </CodeSurfer>
    </Layout>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`trace_ts = trace_timestamp`}</li>
        <li parentName="ul">{`The time stamp (Ts) has the same form as returned by erlang:now(): {MegaSecs, Secs, MicroSecs}`}</li>
        <li parentName="ul">{`in/out schedule`}</li>
        <li parentName="ul">{`Turns on (if How == true) or off (if How == false) the trace flags in FlagList for the process or processes represented by PidPortSpec.`}</li>
        <li parentName="ul">{`Tracer options: all | send | 'receive' | procs | ports | call | arity | return_to | silent | running | exiting | running_procs | running_ports | garbage_collection | timestamp | cpu_timestamp | monotonic_timestamp | strict_monotonic_timestamp | set_on_spawn | set_on_first_spawn | set_on_link | set_on_first_link |`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3><inlineCode parentName="h3">{`:erlang.trace`}</inlineCode>{` Options`}</h3>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-erlang",
            "metastring": "title=\":erlang.trace Options\"",
            "title": "\":erlang.trace",
            "Options\"": true
          }}>{`erlang:trace(PidPortSpec, How, FlagList) -> integer()
Types
PidPortSpec =
    pid() |
    port() |
    all | processes | ports | existing | existing_processes |
    existing_ports | new | new_processes | new_ports
How = boolean()
FlagList = [trace_flag()]
trace_flag() =
    all | send | 'receive' | procs | ports | call | arity |
    return_to | silent | running | exiting | running_procs |
    running_ports | garbage_collection | timestamp |
    cpu_timestamp | monotonic_timestamp |
    strict_monotonic_timestamp | set_on_spawn |
    set_on_first_spawn | set_on_link | set_on_first_link |
    {tracer, pid() | port()} |
    {tracer, module(), term()}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "title=\":erlang.trace Options\" subtitle=\"Send/Receive messages\" 11[11:26]",
            "title": "\":erlang.trace",
            "Options\"": true,
            "subtitle": "\"Send/Receive",
            "messages\"": true,
            "11[11:26]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "title=\":erlang.trace Options\" subtitle=\"Trace Function Calls\" 11[46:50]",
            "title": "\":erlang.trace",
            "Options\"": true,
            "subtitle": "\"Trace",
            "Function": true,
            "Calls\"": true,
            "11[46:50]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "title=\":erlang.trace Options\" subtitle=\"Traces scheduling of processes\" 12[26:32]",
            "title": "\":erlang.trace",
            "Options\"": true,
            "subtitle": "\"Traces",
            "scheduling": true,
            "of": true,
            "processes\"": true,
            "12[26:32]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "title=\":erlang.trace Options\" subtitle=\"Traces garbage collections of processes.\" 13[20:38]",
            "title": "\":erlang.trace",
            "Options\"": true,
            "subtitle": "\"Traces",
            "garbage": true,
            "collections": true,
            "of": true,
            "processes.\"": true,
            "13[20:38]": true
          }}>{``}</code></pre>
      </CodeSurfer>
    </Layout>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`Garbagn Collection message tags: gc_minor_start, gc_max_heap_size, and gc_minor_end.`}</li>
        <li parentName="ul">{`gc_max_heap_size = When process hits max_heap_size`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Trace a function`}</h3>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Start the trace collector\"",
            "subtitle": "\"Start",
            "the": true,
            "trace": true,
            "collector\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Enable tracing\"",
            "subtitle": "\"Enable",
            "tracing\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, pid}])
59
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Trace function calls\" 3[36:40]",
            "subtitle": "\"Trace",
            "function": true,
            "calls\"": true,
            "3[36:40]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Set the trace pattern\"",
            "subtitle": "\"Set",
            "the": true,
            "trace": true,
            "pattern\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, pid}])
59
iex(3)> :erlang.trace_pattern({String, :downcase, 1}, true, [])
1
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Enable tracing for all messages to that function\" 5[55:59]",
            "subtitle": "\"Enable",
            "tracing": true,
            "for": true,
            "all": true,
            "messages": true,
            "to": true,
            "that": true,
            "function\"": true,
            "5[55:59]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Do something traceable\"",
            "subtitle": "\"Do",
            "something": true,
            "traceable\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, pid}])
59
iex(3)> :erlang.trace_pattern({String, :downcase, 1}, true, [])
1
iex(4)> String.downcase("FOO")
"foo"
{:trace, #PID<0.109.0>, :call, {String, :downcase, ["FOO"]}}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Stop the trace\"",
            "subtitle": "\"Stop",
            "the": true,
            "trace\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, pid}])
59
iex(3)> :erlang.trace_pattern({String, :downcase, 1}, true, [])
1
iex(4)> String.downcase("FOO")
"foo"
{:trace, #PID<0.109.0>, :call, {String, :downcase, ["FOO"]}}
iex(5)> :erlang.trace(:all, false, [:call, {:tracer, pid}])
59
iex(6)> String.downcase("FOO")
"foo"
`}</code></pre>
      </CodeSurfer>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`This is great, but...can we get more specific?`}</h2>
      <Appear mdxType="Appear">
        <h2>{`Yes! Match Specs`}</h2>
      </Appear>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Start the trace collector\"",
            "subtitle": "\"Start",
            "the": true,
            "trace": true,
            "collector\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Start the tracer\"",
            "subtitle": "\"Start",
            "the": true,
            "tracer\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, tracer_pid}])
59
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Setup the match spec\"",
            "subtitle": "\"Setup",
            "the": true,
            "match": true,
            "spec\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, tracer_pid}])
59
iex(3)> :dbg.fun2ms(fn [s] when s == "HI" -> :return_trace end)
[{[:"$1"], [{:==, :"$1", "HI"}], [:return_trace]}]
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Setup the match spec\"",
            "subtitle": "\"Setup",
            "the": true,
            "match": true,
            "spec\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, tracer_pid}])
59
iex(3)> :dbg.fun2ms(fn [s] when s == "HI" -> :return_trace end)
[{[:"$1"], [{:==, :"$1", "HI"}], [:return_trace]}]
iex(4)> ms = [{[:"$1"], [{:==, :"$1", "HI"}], [{:return_trace}]}]
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"A note about return_trace\" 5[45:58],6[34:47],7[48:62]",
            "subtitle": "\"A",
            "note": true,
            "about": true,
            "return_trace\"": true,
            "5[45:58],6[34:47],7[48:62]": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, tracer_pid}])
59
iex(3)> :dbg.fun2ms(fn [s] when s == "HI" -> :return_trace end)
[{[:"$1"], [{:==, :"$1", "HI"}], [:return_trace]}]
iex(4)> ms = [{[:"$1"], [{:==, :"$1", "HI"}], [{:return_trace}]}]
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Setup the Pattern Trace\"",
            "subtitle": "\"Setup",
            "the": true,
            "Pattern": true,
            "Trace\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, tracer_pid}])
59
iex(3)> :dbg.fun2ms(fn [s] when s == "HI" -> :return_trace end)
[{[:"$1"], [{:==, :"$1", "HI"}], [:return_trace]}]
iex(4)> ms = [{[:"$1"], [{:==, :"$1", "HI"}], [{:return_trace}]}]
iex(5)> :erlang.trace_pattern({String, :downcase, 1}, ms, [])
1
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Call the function\"",
            "subtitle": "\"Call",
            "the": true,
            "function\"": true
          }}>{`iex(1)> pid = Trace.start()
#PID<0.118.0>
iex(2)> :erlang.trace(:all, true, [:call, {:tracer, tracer_pid}])
59
iex(3)> :dbg.fun2ms(fn [s] when s == "HI" -> :return_trace end)
[{[:"$1"], [{:==, :"$1", "HI"}], [:return_trace]}]
iex(4)> ms = [{[:"$1"], [{:==, :"$1", "HI"}], [{:return_trace}]}]
iex(5)> :erlang.trace_pattern({String, :downcase, 1}, ms, [])
1
iex(6)> String.downcase("FOO")
"foo"
iex(7)> String.downcase("HI")
{:trace, #PID<0.109.0>, :call, {String, :downcase, ["HI"]}}
"hi"
{:trace, #PID<0.109.0>, :return_from, {String, :downcase, 1}, "hi"}
`}</code></pre>
      </CodeSurfer>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Matchspecs`}</h1>
      <pre><code parentName="pre" {...{
          "className": "language-elixir"
        }}>{`:dbg.fun2ms(fn [s] when s == "bd64bca0-721f-4162-b95c-f779d9999d0c" -> nil end)
`}</code></pre>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">{`Looks like a function... sorta`}</li>
          <li parentName="ul">{`Can use most guards`}</li>
          <li parentName="ul">{`Can use variables from outside in guard`}</li>
          <li parentName="ul">{`Dealing with the function body is hard in elixir`}</li>
        </ul>
      </Steps>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurferColumns mdxType="CodeSurferColumns">
        <Step mdxType="Step">
          <pre><code parentName="pre" {...{
              "className": "language-erlang",
              "metastring": "title=\"In Erlang\"",
              "title": "\"In",
              "Erlang\"": true
            }}>{`1> dbg:fun2ms(
 >   fun([M,N]) when N > 3 ->
 >     return_trace()
 > end).
[{['$1','$2'],[{'>','$2',3}],[{return_trace}]}]
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-elixir",
              "metastring": "title=\"In Elixir\"",
              "title": "\"In",
              "Elixir\"": true
            }}>{`iex(1)> :dbg.fun2ms(
      >   fn [m, n] when n > 3 ->
      >     return_trace()
      > end)
`}</code></pre>
        </Step>
        <Step mdxType="Step">
          <pre><code parentName="pre" {...{
              "className": "language-erlang",
              "metastring": "title=\"In Erlang\"",
              "title": "\"In",
              "Erlang\"": true
            }}>{`1> dbg:fun2ms(
 >   fun([M,N]) when N > 3 ->
 >     return_trace()
 > end).
[{['$1','$2'],[{'>','$2',3}],[{return_trace}]}]
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-elixir",
              "metastring": "title=\"In Elixir\" subtitle=\"Boom!\"",
              "title": "\"In",
              "Elixir\"": true,
              "subtitle": "\"Boom!\""
            }}>{`iex(1)> :dbg.fun2ms(
      >   fn [m, n] when n > 3 ->
      >     return_trace()
      > end)
** (CompileError) iex:1: undefined function return_trace/0
    (elixir 1.10.2) src/elixir_fn.erl:15:
      anonymous fn/4 in :elixir_fn.expand/3
    (stdlib 3.12.1) lists.erl:1354:
      :lists.mapfoldl/3
    (elixir 1.10.2) src/elixir_fn.erl:20:
      :elixir_fn.expand/3
    (stdlib 3.12.1) lists.erl:1354:
      :lists.mapfoldl/3
`}</code></pre>
        </Step>
        <Step mdxType="Step">
          <pre><code parentName="pre" {...{
              "className": "language-erlang",
              "metastring": "title=\"In Erlang\"",
              "title": "\"In",
              "Erlang\"": true
            }}>{`1> dbg:fun2ms(
 >   fun([M,N]) when N > 3 ->
 >     return_trace()
 > end).
[{['$1','$2'],[{'>','$2',3}],[{return_trace}]}]
`}</code></pre>
          <pre><code parentName="pre" {...{
              "className": "language-elixir",
              "metastring": "title=\"In Elixir\" subtitle=\"Modify the 3rd element of the tuple\"",
              "title": "\"In",
              "Elixir\"": true,
              "subtitle": "\"Modify",
              "the": true,
              "3rd": true,
              "element": true,
              "of": true,
              "tuple\"": true
            }}>{`iex(1)> :dbg.fun2ms(
      >   fn [m, n] when n > 3 ->
      >     :return_trace
      > end)
[{[:"$1",:"$2"],[{:>,:"$2",3}],[:return_trace]}]
`}</code></pre>
        </Step>
      </CodeSurferColumns>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <p>{`If you do this a lot in Elixir you can use `}<a parentName="p" {...{
          "href": "https://github.com/ericmj/ex2ms"
        }}>{`ex2ms`}</a></p>
    </Layout>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`You don't necessarily need this on the box you are using it on`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3><inlineCode parentName="h3">{`:dbg`}</inlineCode></h3>
      <ul>
        <li parentName="ul">{`Wrapper around `}<inlineCode parentName="li">{`:erlang.trace`}</inlineCode></li>
        <li parentName="ul">{`Handles starting the trace process`}</li>
        <li parentName="ul">{`Robust, ~150 lines of erlang`}</li>
        <li parentName="ul">{`Supports tracing other nodes`}</li>
      </ul>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Trace a process`}</h3>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Start the process\"",
            "subtitle": "\"Start",
            "the": true,
            "process\"": true
          }}>{`iex(1)> echo_pid = Echo.start
#PID<0.118.0>
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Setup tracing on the local node\"",
            "subtitle": "\"Setup",
            "tracing": true,
            "on": true,
            "the": true,
            "local": true,
            "node\"": true
          }}>{`iex(1)> echo_pid = Echo.start
#PID<0.118.0>
iex(4)> :dbg.tracer()
{:ok, #PID<0.120.0>}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Setup tracing on the process\"",
            "subtitle": "\"Setup",
            "tracing": true,
            "on": true,
            "the": true,
            "process\"": true
          }}>{`iex(1)> echo_pid = Echo.start
#PID<0.118.0>
iex(4)> :dbg.tracer()
{:ok, #PID<0.120.0>}
iex(5)> :dbg.p(echo_pid, [:all])
{:ok, [{:matched, :nonode@nohost, 1}]}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Call the process\"",
            "subtitle": "\"Call",
            "the": true,
            "process\"": true
          }}>{`iex(1)> echo_pid = Echo.start
#PID<0.118.0>
iex(4)> :dbg.tracer()
{:ok, #PID<0.120.0>}
iex(5)> :dbg.p(echo_pid, [:all])
{:ok, [{:matched, :nonode@nohost, 1}]}
iex(6)> Echo.call(echo_pid, :hi)
:hi
(<0.118.0>) in {'Elixir.Echo',loop,0} (Timestamp: {1588,724836,891436})
(<0.118.0>) << {<0.109.0>,hi} (Timestamp: {1588,724836,891679})
(<0.118.0>) <0.109.0> ! hi (Timestamp: {1588,724836,891684})
(<0.118.0>) out {'Elixir.Echo',loop,0} (Timestamp: {1588,724836,891687})
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Stop and cleanup\"",
            "subtitle": "\"Stop",
            "and": true,
            "cleanup\"": true
          }}>{`iex(1)> echo_pid = Echo.start
#PID<0.118.0>
iex(4)> :dbg.tracer()
{:ok, #PID<0.120.0>}
iex(5)> :dbg.p(echo_pid, [:all])
{:ok, [{:matched, :nonode@nohost, 1}]}
iex(6)> Echo.call(echo_pid, :hi)
:hi
(<0.118.0>) in {'Elixir.Echo',loop,0} (Timestamp: {1588,724836,891436})
(<0.118.0>) << {<0.109.0>,hi} (Timestamp: {1588,724836,891679})
(<0.118.0>) <0.109.0> ! hi (Timestamp: {1588,724836,891684})
(<0.118.0>) out {'Elixir.Echo',loop,0} (Timestamp: {1588,724836,891687})
iex(8)> :dbg.stop_clear()
:ok
`}</code></pre>
      </CodeSurfer>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Trace a function`}</h3>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Start the tracer and setup tracing for all processes\"",
            "subtitle": "\"Start",
            "the": true,
            "tracer": true,
            "and": true,
            "setup": true,
            "tracing": true,
            "for": true,
            "all": true,
            "processes\"": true
          }}>{`iex(1)> :dbg.tracer()
{:ok, #PID<0.111.0>}
iex(2)> :dbg.p(:all,:c)
{:ok, [{:matched, :nonode@nohost, 60}]}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Setup tracing for the function\"",
            "subtitle": "\"Setup",
            "tracing": true,
            "for": true,
            "the": true,
            "function\"": true
          }}>{`iex(1)> :dbg.tracer()
{:ok, #PID<0.111.0>}
iex(2)> :dbg.p(:all,:c)
{:ok, [{:matched, :nonode@nohost, 60}]}
iex(3)>  :dbg.tpl(String, :downcase, :x)
{:ok, [{:matched, :nonode@nohost, 2}, {:saved, :x}]}
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Call the function\"",
            "subtitle": "\"Call",
            "the": true,
            "function\"": true
          }}>{`iex(1)> :dbg.tracer()
{:ok, #PID<0.111.0>}
iex(2)> :dbg.p(:all,:c)
{:ok, [{:matched, :nonode@nohost, 60}]}
iex(3)>  :dbg.tpl(String, :downcase, :x)
{:ok, [{:matched, :nonode@nohost, 2}, {:saved, :x}]}
iex(4)> String.downcase("HI")
(<0.109.0>) call 'Elixir.String':downcase(<<"HI">>)
(<0.109.0>) call 'Elixir.String':downcase(<<"HI">>,default)
(<0.109.0>) returned from 'Elixir.String':downcase/2 -> <<"hi">>
(<0.109.0>) returned from 'Elixir.String':downcase/1 -> <<"hi">>
"hi"
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Cleanup\"",
            "subtitle": "\"Cleanup\""
          }}>{`iex(1)> :dbg.tracer()
{:ok, #PID<0.111.0>}
iex(2)> :dbg.p(:all,:c)
{:ok, [{:matched, :nonode@nohost, 60}]}
iex(3)>  :dbg.tpl(String, :downcase, :x)
{:ok, [{:matched, :nonode@nohost, 2}, {:saved, :x}]}
iex(4)> String.downcase("HI")
(<0.109.0>) call 'Elixir.String':downcase(<<"HI">>)
(<0.109.0>) call 'Elixir.String':downcase(<<"HI">>,default)
(<0.109.0>) returned from 'Elixir.String':downcase/2 -> <<"hi">>
(<0.109.0>) returned from 'Elixir.String':downcase/1 -> <<"hi">>
"hi"
iex(5)> :dbg.stop_clear()
:ok
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Shortcuts\" 1[21:23],3[38:40]",
            "subtitle": "\"Shortcuts\"",
            "1[21:23],3[38:40]": true
          }}>{`iex(2)> :dbg.p(:all,:c)
{:ok, [{:matched, :nonode@nohost, 60}]}
iex(3)>  :dbg.tpl(String, :downcase, :x)
{:ok, [{:matched, :nonode@nohost, 2}, {:saved, :x}]}
`}</code></pre>
      </CodeSurfer>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <p>{`The 3rd argument to `}<inlineCode parentName="p">{`:dbg.tpl(Module, :function, _)`}</inlineCode>{` can also be a MatchSpec`}</p>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Pitfalls of `}<inlineCode parentName="h3">{`:erlang.trace`}</inlineCode>{` and `}<inlineCode parentName="h3">{`:dbg`}</inlineCode></h3>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">{`Arguably confusing interface`}</li>
          <li parentName="ul">{`Potential to dump unbounded amounts of tracing`}</li>
          <li parentName="ul">{`Ability to deadlock`}</li>
        </ul>
      </Steps>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Recon`}</h2>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul"><inlineCode parentName="li">{`:erlang.trace`}</inlineCode>{` wrapper by Fred Hebert`}</li>
          <li parentName="ul">{`Production Safe!`}
            <ul parentName="li">
              <li parentName="ul">{`Tracer links to shell (group leader)`}</li>
              <li parentName="ul">{`Formatter links to tracer`}</li>
              <li parentName="ul">{`Gracefully shuts down on remote shell disconnect`}</li>
              <li parentName="ul">{`Enforces limited count of traces`}</li>
            </ul>
          </li>
          <li parentName="ul">{`Single node only`}</li>
        </ul>
      </Steps>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h3>{`Trace a function`}</h3>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <CodeSurfer mdxType="CodeSurfer">
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Setup trace for the function call\"",
            "subtitle": "\"Setup",
            "trace": true,
            "for": true,
            "the": true,
            "function": true,
            "call\"": true
          }}>{`iex(1)> :recon_trace.calls({String, :downcase, :return_trace}, 2)
2
`}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Return trace shortcut\" 1[48:60]",
            "subtitle": "\"Return",
            "trace": true,
            "shortcut\"": true,
            "1[48:60]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-diff",
            "metastring": "subtitle=\"Limit number of calls\" 1[64]",
            "subtitle": "\"Limit",
            "number": true,
            "of": true,
            "calls\"": true,
            "1[64]": true
          }}>{``}</code></pre>
        <pre><code parentName="pre" {...{
            "className": "language-elixir",
            "metastring": "subtitle=\"Call the function\"",
            "subtitle": "\"Call",
            "the": true,
            "function\"": true
          }}>{`iex(2)> String.downcase("HI")
"hi"

9:24:54.599054 <0.682.0> 'Elixir.String':downcase(<<"HI">>)

9:24:54.607330 <0.682.0> 'Elixir.String':downcase/1 --> <<"hi">>
Recon tracer rate limit tripped.
`}</code></pre>
      </CodeSurfer>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">{`3rd argument to tuple can be match spec`}
            <pre parentName="li"><code parentName="pre" {...{}}>{`:recon_trace.calls({String, :downcase, match_spec}, _)
`}</code></pre>
          </li>
          <li parentName="ul"><inlineCode parentName="li">{`:recon_trace.calls/3`}</inlineCode>{` takes a 3rd argument that can specify the pid to trace among other things`}</li>
        </ul>
      </Steps>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`What's left?`}</h2>
      <Steps mdxType="Steps">
        <ul>
          <li parentName="ul">{`Sequential Tracing `}<inlineCode parentName="li">{`:seq_trace`}</inlineCode>
            <blockquote parentName="li">
              <p parentName="blockquote">{`Sequential tracing is a way to trace a sequence of messages sent between different local or remote processes, where the sequence is initiated by a single message.`}</p>
              <p parentName="blockquote">{`-- `}<cite><a parentName="p" {...{
                    "href": "https://erlang.org/doc/man/seq_trace.html#whatis"
                  }}>{`Erlang -- seq_trace`}</a></cite></p>
            </blockquote>
          </li>
          <li parentName="ul">{`Production safe debugging across nodes`}</li>
          <li parentName="ul"><inlineCode parentName="li">{`:sys`}</inlineCode>{` module`}</li>
        </ul>
      </Steps>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h2>{`Resources`}</h2>
      <ul>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://joeellis.la/iex-remsh-shells/"
          }}>{`Connect to Running Elixir Applications with IEx Remote Shell`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://gist.github.com/id/cba5dbf7653d7eab6a03"
          }}>{`Tracing in Erlang (Gist)`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://ferd.github.io/recon/"
          }}>{`Recon`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://www.erlang-in-anger.com/"
          }}>{`Erlang in Anger`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "https://github.com/zhongwencool/observer_cli"
          }}>{`Observer Cli`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://blog.plataformatec.com.br/2016/04/debugging-techniques-in-elixir-lang/"
          }}>{`Debugging Techniques in Elixir`}</a></li>
        <li parentName="ul"><a parentName="li" {...{
            "href": "http://www.erlang-factory.com/upload/presentations/316/dbg%5B1%5D.pdf"
          }}>{`Erlang Factory - dbg/ttb`}</a></li>
      </ul>
    </Layout>
    <hr></hr>
    <Layout mdxType="Layout">
      <h1>{`Questions?`}</h1>
      <Image src={punch} width="80vw" size="contain" mdxType="Image" />
    </Layout>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;